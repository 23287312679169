import useIntegrationStore from "../zustand/integration";
import axios from "../zustand/utils";

export const useIntegration = () => {
  const setIntegrations = useIntegrationStore((s) => s.setIntegrations);

  const integrateWithCRM = async (params, integrationID) => {
    const integrations = useIntegrationStore.getState().integrations;

    try {
      const config = {
        method: "post",
        url: `/auth/crm`,
        data: params,
      };
      const res = await axios(config);
      if (res.status === 200) {
        const copyIntegration = [...integrations];
        for (let integration of copyIntegration) {
          console.log(integration.id, integrationID);
          if (integration["id"] === integrationID) {
            integration["integrated"] = true;
          }
        }
        console.log("yuiii");
        console.log(copyIntegration);
        setIntegrations(copyIntegration);
      }
    } catch (err) {
      console.error("Error in data fetch:", err);
    }
  };

  return { integrateWithCRM };
};

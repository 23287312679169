import React, { useEffect } from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import Card from "../components/card/Card.js";
import { INTEGRATIONS_CALLBACK_URL } from "../config";
import useIntegrationStore from "../zustand/integration";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useAppStore from "../zustand/app.js";
import { MODALS } from "../types/modal.types.js";
import useAuthStore from "../zustand/auth";

const IntegrationItem = ({
  name,
  status,
  logo,
  onStatusClick,
  integrated,
  enabled,
}) => (
  <Card p="20px">
    <Flex direction={{ base: "column" }} justify="center">
      <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
        <Image
          src={logo}
          w={{ base: "100%", "3xl": "100%" }}
          h="100px"
          borderRadius="20px"
          style={{objectFit: 'cover'}}
        />
      </Box>
      <Flex flexDirection="column" justify="space-between" h="100%">
        <Flex
          justify="space-between"
          direction={{
            base: "row",
            md: "column",
            lg: "row",
            xl: "column",
            "2xl": "row",
          }}
          mb="auto"
        >
          <Flex direction="column">
            <Text
              fontSize={{
                base: "xl",
                md: "lg",
                lg: "lg",
                xl: "lg",
                "2xl": "md",
                "3xl": "lg",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          align="start"
          justify="space-between"
          direction={{
            base: "row",
            md: "column",
            lg: "row",
            xl: "column",
            "2xl": "row",
          }}
          mt="25px"
        >
          <Button
            onClick={() => onStatusClick(name)}
            variant={integrated ? "outline" : "darkBrand"}
            colorScheme="green"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            isDisabled={integrated || !enabled}
            px="24px"
            py="5px"
          >
            {integrated ? "Live" : enabled ? "Connect" : "Coming Soon"}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Card>
);

const zohoAuthLink = (clientId, redirect_uri, client) =>
  `https://accounts.zoho.in/oauth/v2/auth?response_type=code&client_id=${clientId}&scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.notifications.ALL,ZohoCRM.org.READ&redirect_uri=${redirect_uri}&prompt=consent&access_type=offline&state=${client}:zoho`;

const Popup = () => {
  const showPopup = useIntegrationStore((state) => state.showPopup);
  const selected = useIntegrationStore((state) => state.selected);
  const closePopup = useIntegrationStore((state) => state.closePopup);

  return (
    <div className={`popup-background ${showPopup ? "visible" : "hidden"}`}>
      <div className="popup">
        <h2>{selected && selected.name}</h2>
        <img
          src={selected && selected.logo}
          alt={`${selected && selected.name} logo`}
          className="integration-logo"
        />
        <button className="close-button" onClick={() => closePopup(selected)}>
          Close
        </button>
      </div>
    </div>
  );
};

const Integration = () => {
  const toggleModal = useAppStore((state) => state.toggleModal);
  const integrations = useIntegrationStore((state) => state.integrations);
  const setSelectedIntegration = useIntegrationStore(
    (state) => state.setSelectedIntegration
  );
  // const crmIntegrate = useIntegrationStore((state) => state.crmIntegrate)
  const openPopup = useIntegrationStore((state) => state.openPopup);

  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    // const getToken = async () => {
    //   const token = await getAccessTokenSilently()
    //   console.log(token);
    //   return token;
    // }
    // getToken();
    console.log("isAuthenticated: ", isAuthenticated);
  }, []);

  const handleCredentialRedirect = (id) => {
    if (id === "zohoCRM") {
      window.location.href = zohoAuthLink(
        "1000.FVD8PK3ZCJNXELSDP4UXIFGZ7WIBSQ",
        INTEGRATIONS_CALLBACK_URL,
        useAuthStore.getState().user.org_name
      );
    } else if (id === "leadsquared") {
      toggleModal(MODALS.integration, {
        id: "leadsquared",
        name: "LeadSquared",
      });
    }
  };

  useEffect(() => {
    console.log("abc chirag 3");
  }, []);

  useEffect(() => {
    console.log(integrations);
    console.log("abc chirag 2");
  }, [integrations]);

  // useEffect(() => {
  //   // query params
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const stateVal = urlParams.get("state");
  //   const params = {
  //     state: urlParams.get("state"),
  //     code: urlParams.get("code"),
  //     location: urlParams.get("location"),
  //     "accounts-server": urlParams.get('accounts-server')
  //   }
  //   console.log(stateVal)
  //   if (stateVal) {
  //     crmIntegrate(params, 'zohoCRM').then((value) => {
  //     openPopup()
  //   })
  //
  //   }
  // }, []);

  return (
    <div className="app">
      <main>
        <div className="header">
          <h1>Integrations</h1>
          <p>Start syncing data with your favorite CRM now!</p>
        </div>

        <div className="integration-container">
          {integrations &&
            integrations.map((integration) => (
              <IntegrationItem
                key={integration.name}
                {...integration}
                onStatusClick={() => {
                  setSelectedIntegration(integration);
                  handleCredentialRedirect(integration.id);
                }}
              />
            ))}
        </div>
      </main>
      <Popup />
    </div>
  );
};

export default Integration;

import React from "react";
import { useEffect } from "react";
import { useIntegration } from "../hooks/integration";
import { useNavigate } from "react-router-dom";

export const IntegrationsCallbackPage = () => {
  const { integrateWithCRM } = useIntegration();
  const navigate = useNavigate();

  useEffect(() => {
    // query params
    const urlParams = new URLSearchParams(window.location.search);
    const stateVal = urlParams.get("state");
    const params = {
      state: urlParams.get("state"),
      code: urlParams.get("code"),
      location: urlParams.get("location"),
      "accounts-server": urlParams.get("accounts-server"),
    };
    if (stateVal) {
      integrateWithCRM(params, "zohoCRM").then((value) => {
        return navigate("/integrations");
      });
    }
  }, [navigate]);

  return (
    <div>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Integrating....
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>Redirecting to Integrations.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export const ENV = process.env.REACT_APP_VERCEL_ENV;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

let api_url = "";
if (ENV === "production") {
  api_url = "https://api.revspot.ai";
} else if (ENV === "preview") {
  api_url = "https://api.staging.revspot.ai";
} else {
  //? For testing purpose we are using staging backend
  // api_url = "0.0.0.0:80/"
  api_url = "https://api.staging.revspot.ai";
}

export const API_URL = api_url;

let app_url = "";
if (ENV === "production") {
  app_url = "https://app.revspot.ai";
} else if (ENV === "preview") {
  app_url = "https://app.staging.revspot.ai";
} else {
  app_url = "http://localhost:3000";
}

export const AUTH_CALLBACK_URL = app_url + "/callback";
export const INTEGRATIONS_CALLBACK_URL = app_url + "/integrations/callback";
export const IS_DEV = ENV === "production" || ENV === "preview";
